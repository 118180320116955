<template>
  <div class="m_chart" :style="chartStyle">
    <div class="main" :id="chartId"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    chartName: {
      type: String,
      default: null
    },
    chartStyle: {
      type: Object,
      default: null
    },
    chartType: {
      type: String,
      default: 'pie'
    },
    chartData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartId: 'Chart' + parseInt(Math.random() * 10000)
    }
  },
  mounted() {
    var myChart = echarts.init(document.getElementById(this.chartId), 'dark')
    if (this.chartType === 'pie') {
      this.init(myChart)
    } else if (this.chartType === 'bar') {
      this.initBar(myChart)
    }
    myChart.on('click', params => {
      this.$emit('click', this.chartName, params)
    })
  },
  methods: {
    init(myChart) {
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.chartData.title,
          textStyle: {
            color: 'rgb(255,255,255)'
          },
          padding: [10, 0, 0, 30]
        },
        textStyle: {
          color: '#fff'
        },
        backgroundColor: 'rgba(0,0,0,0.0)',
        color: this.chartData.color ?? ['rgb(0,94,170)', 'rgb(0,152,257)', 'rgb(43,130,29)', 'rgb(232,124,37)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
        tooltip: {
          trigger: 'item',
          formatter: '{b}：{c}个<br/>占比： {d}%'
        },
        series: [
          {
            name: '分时网点 TOP500 饱和状态',
            type: 'pie',
            radius: this.chartData.pie.radius,
            roseType: this.chartData.pie.roseType,
            data: this.chartData.data,
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: idx => {
              return Math.random() * 200
            }
          }
        ]
      })
    },
    initBar(myChart) {
      console.log(this.chartData)
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.chartData.title,
          textStyle: {
            color: 'rgb(255,255,255)'
          },
          padding: [10, 0, 0, 30]
        },
        textStyle: {
          color: '#fff'
        },
        backgroundColor: 'rgba(0,0,0,0.0)',
        color: this.chartData.color ?? ['rgb(0,94,170)', 'rgb(0,152,257)', 'rgb(43,130,29)', 'rgb(232,124,37)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          top: '10px',
          right: '5px',
          data: this.chartData.data.legend
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.data.category
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: this.chartData.data.series
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m_chart {
  position: absolute;

  .main {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    background-color: #282839;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
  }
}
</style>
